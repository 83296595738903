import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import {
  bodyCopySizeTheme as BODY_COPY_SIZE,
  headingSizeTheme as HEADING_SIZE,
  getThemeStyles,
} from 'holded/lib/styles';
import { getCustomTag, isDefault } from 'holded/lib/utils';
import { Header2Section } from 'holded/modules/cms/domain/components/header';
import { defaultThemeValues, HeaderTheme } from 'holded/modules/cms/ui/sections/header/HeaderTheme';

const NextImage = dynamic(() => import('holded/modules/cms/ui/shared/components/Image'));
const CardIcon = dynamic(() => import('holded/modules/cms/ui/shared/components/CardIcon'));
const Header2 = ({ title, description, image, imageMobile, cards, background, customTag }: Header2Section) => {
  const [themeValues, setThemeValues] = useState(defaultThemeValues);

  const CustomTitle = getCustomTag('h1', customTag?.headingTag);
  const defaultHeadingSize = isDefault(customTag?.headingSize);
  const defaultBodySize = isDefault(customTag?.bodyCopySize);
  const headingSize = `text-h4 md:${HEADING_SIZE[customTag?.headingSize ?? 'default']}`;

  useEffect(() => {
    const themeStyles = getThemeStyles(HeaderTheme.header, background);
    setThemeValues({ ...themeValues, ...themeStyles });
  }, []);

  return (
    <div className={`bg-white`}>
      <div className="relative pb-32">
        <div className={`absolute inset-0 h-header w-full ${themeValues.background} flex justify-center`}>
          {image?.data && (
            <div className={'w-full max-w-7xl flex justify-end'}>
              <NextImage media={image.data} classImg={'object-cover w-max !h-full hidden lg:block'} />
            </div>
          )}
          {imageMobile?.data && (
            <div className={'w-full flex justify-end md:hidden'}>
              <NextImage media={imageMobile.data} classImg={'object-cover w-max !h-full'} />
            </div>
          )}
        </div>
        <div className={`relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8`}>
          {title && (
            <CustomTitle
              className={`${themeValues.titleColor} ${
                defaultHeadingSize ? 'text-h4 md:text-h1' : headingSize
              } font-extrabold tracking-tight`}
            >
              {title}
            </CustomTitle>
          )}
          {description && (
            <p
              className={`${themeValues.descriptionColor} mt-6 max-w-3xl ${
                defaultBodySize ? 'text-b1' : BODY_COPY_SIZE[customTag?.bodyCopySize ?? 'default']
              } leading-7`}
            >
              {description}
            </p>
          )}
        </div>
      </div>

      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-16 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {cards?.map((card) => (
            <CardIcon key={card.id} {...card} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Header2;
